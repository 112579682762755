.css-2b097c-container {
outline:0
}
.css-2b097c-container .css-yk16xz-control {
  border-radius: 0px;
  min-height: 42px;
}
.css-b8ldur-Input input {
  height: 30px !important;
}
.css-2b097c-container .css-lpahdxg-control {
  box-shadow: none;
  border-color: 1px solid #d2d2d2;
}
.css-yk16xz-control .css-g1d714-ValueContainer {
  padding: 7px 8px 0;
}
