.form-control {
  margin-bottom: 5px;
  padding: 4px 15px;
}
form label {
  font-size: 0.9rem;
  padding: 2px 0 5px;
  display: block;
  color: #616161;
  margin-top: 4px;
}
form input[type="text"],
form input[type="email"],
form input[type="number"],
form input[type="password"] {
  padding: 0.6em;
  font-weight: 500;
  border-radius: 0px;
  /* width: fit-content; */
  /* width: -webkit-fill-available; */
  width: 100%;
  box-sizing: border-box;
  box-shadow: none;
  outline: 0;
  border: 1px solid #e2e2e2;
  color: #555;
  font-size: 0.95rem;
  margin: 2px 0;
  height: 36px;
}
form input.noBorder {
  border: 0px;
  border-bottom: 1px solid #d2d2d2;
  padding: 0.5rem;
}
form input.noMargin {
  margin: 0px;
}
form .error {
  color: #f23436;
  padding: 3px 0;
  font-size: 14px;
}

form .arrayFields input {
  width: 80%;
  margin: 5px;
}
form textarea {
  padding: 0.6em;
  /* font-weight: 500; */
  border-radius: 0px;
  /* width: fit-content; */
  width: -webkit-fill-available;
  box-shadow: none;
  outline: 0;
  border: 1px solid #ccc;
  color: #555;
  font-family: inherit;
  font-size: 0.95rem;
  margin: 2px 0;
}
form input::placeholder,
form textarea::placeholder {
  color: #ccc;
}

form select {
  border-radius: 0px;
  border: 1px solid #d7d7d7;
  box-shadow: none;
  color: #555;
  background-color: #fff;
  width: 100%;
  height: 38px;
  padding: 6px 8px;
  font-size: 14px;
  outline: 0;
}
