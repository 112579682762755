header {
  background-color: rgb(21, 21, 21);
  box-shadow: rgb(36, 36, 36) 0px -1px 0px inset;
  height: 53px;
  border-bottom: 1px solid #444;
}
header .MuiSvgIcon-root {
  color: #fff;
  font-size: 30px;
}
header .MuiButton-root:hover {
  background: transparent;
}
header .MuiAvatar-img {
  height: 30px;
  width: 30px;
  border-radius: 50px;
}
header .MuiAvatar-root.MuiAvatar-colorDefault {
  height: 30px;
  width: 30px;
  background: #fff;
}
header .MuiButton-text.dropdownMenu {
  padding: 8px 15px 6px 4px;
  min-width: auto;
}
.MuiButton-text.dropdownMenu {
  padding: 10px 20px;
}

.paperAdminInfo {
  padding: 15px;
  margin: 0px;
  font-size: 15.5px;
  line-height: 21px;
}

.paperAdminInfo .email {
  color: #777;
}

.MuiList-root .MuiMenuItem-root {
  font-size: 0.9rem;
}
.MuiList-root .MuiMenuItem-root svg {
  color: #777779;
  padding-right: 10px;
}
