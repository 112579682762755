.invoiceDiv {
  width: 80%;
  margin: 0 auto;
  padding: 30px 0;
}
.invoiceDiv .flex {
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
}
.invoiceDiv img {
  width: 170px;
  padding: 0 0 10px;
}
.invoiceDiv label {
  display: block;
  font-weight: 450;
  margin: 10px 0 5px;
  line-height: 22px;
}

.invoiceDiv p {
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 21px;
  color: #333;
}

.invoiceDiv table,
.thermalInvoiceDiv table {
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  margin: 30px 0;
}
.invoiceDiv table thead th {
  border: 0.5px solid black;
  border-bottom: 0px;
  font-weight: 600;
  font-size: 14px;
  padding: 17px 20px;
  text-align: left;
}

.invoiceDiv table tr td {
  border: 0.5px solid black;
  font-size: 14px;
  padding: 15px 20px;
  vertical-align: middle;
  text-align: left;
}
.invoiceDiv table tr.payment td {
  border: 0px;
  padding: 10px 15px;
}
.invoiceDiv table tr td:last-child {
  text-align: right;
}
/* .invoiceDiv table tr.payment td:last-child {
  border-right: 0.5px solid black;
}
.invoiceDiv table tr.payment td:nth-last-child(2) {
  border-left: 0.5px solid black;
} */
.invoiceDiv table tr:last-child td:last-child,
.invoiceDiv table tr:last-child td:nth-last-child(2) {
  border-bottom: 0.5px solid #e2e2e2;
  padding: 15px;
  font-weight: 450;
  border-top: 0.5px solid #e2e2e2;
}

.invoiceDiv table tr.payment.line td {
  /* display: none; */
  visibility: hidden;
  padding: 0;
  line-height: 0;
}
.invoiceDiv table tr.line td:last-child,
.invoiceDiv table tr.line td:nth-last-child(2) {
  border-top: 0.5px solid #e2e2e2;
}
.invoiceDiv table tr.nopadding td {
  padding: 5px 15px;
}
.invoiceDiv table tr.noheight td {
  line-height: 0px;
}

.thermalInvoiceDiv {
  padding: 10px 15px;
}
.thermalInvoiceDiv table {
  margin: 15px 0;
}
.thermalInvoiceDiv hr {
  margin: 12px 0;
}
.thermalInvoiceDiv img {
  display: block;
  margin: 0 auto;
  padding: 15px 0;
  width: 150px;
}
.thermalInvoiceDiv h4 {
  margin: 10px 0 5px;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}
.thermalInvoiceDiv p {
  padding: 0;
  margin: 0;
  font-size: 15.5px;
  line-height: 25px;
  color: #333;
}
.thermalInvoiceDiv table tr td {
  padding: 8px;
  vertical-align: top;
  font-size: 15px;
  line-height: 22px;
}
.thermalInvoiceDiv table tr.payment td {
  padding: 4px 8px;
}
.thermalInvoiceDiv table tr.payment:last-child td {
  font-weight: 450;
}
