.star-rating {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.star {
  cursor: pointer;
  width: 1em;
  height: 1em;
  margin: 2px;
  background-color: #d7d7d7;
  -webkit-clip-path: polygon(
    50% 0%,
    63% 38%,
    100% 38%,
    69% 59%,
    82% 100%,
    50% 75%,
    18% 100%,
    31% 59%,
    0% 38%,
    37% 38%
  );
  clip-path: polygon(
    50% 0%,
    63% 38%,
    100% 38%,
    69% 59%,
    82% 100%,
    50% 75%,
    18% 100%,
    31% 59%,
    0% 38%,
    37% 38%
  );
}

.star.selected {
  background-color: #fbad00;
}
